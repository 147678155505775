import { TikTok } from "react-tiktok";

const TikTokVideo: React.FC<{ tikTokUrl: string }> = (props) => {
  return (
    <li>
      <TikTok url={props.tikTokUrl} />
    </li>
  );
};
export default TikTokVideo;
