import { Card } from "react-bootstrap";
import Service from '../../interfaces/service';


const ServicePic: React.FC<{service:Service}> = (props) => {
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Img variant="top" src={process.env.PUBLIC_URL + props.service.url} />
      <Card.Body>
        <Card.Title>{props.service.Title}</Card.Title>
        <Card.Text>
          {props.service.Description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
export default ServicePic;
