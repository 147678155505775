import { Card, Row, Col, Container } from "react-bootstrap";
import { useState } from "react";
import Service from "../../interfaces/service";
import classes from "./Services.module.css";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const [services] = useState<Service[]>([
    {
      Title: "Sandblasting",
      Subtitle: "Blasting off old paint",
      Description:
        "Expert Sandblasting to clean off parts before being powdercoated to perfection",
        url:"/SandBlasting"
    },
    {
      Title: "PowderCoating",
      Subtitle: "Coating parts in Powder",
      Description:
        "Expert Powder coating to leave your parts protected and sharp for years ",
        url:"/PowderCoating"
    },
  ]);

  const navigateHandler = (navigationString: string) => {
    navigate(navigationString);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Services</h1>
        </Col>
      </Row>
      <Row xs={2} md={2} className="g-4">
        {services.map((service) => (
          <Col>
            <Card
              onClick={() => navigateHandler(service.url)}
              border="primary"
              className={classes.card}
            >
              <Card.Body className={classes.cardbody}>
                <Card.Title>{service.Title}</Card.Title>
                <Card.Text>{service.Description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default Services;
