import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classes from './MainNavigation.module.css'; 
const MainNavigation = () => {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
        <img
          alt=""
          src={process.env.PUBLIC_URL + "SPWLogoPS.png"}
          width="30"
          height="30"
        />{' '}
          SherbzPowdrWorx</Navbar.Brand>
        
        <Nav className="me-auto">
          <Nav.Link as="div">
            <NavLink className={(navData) => navData.isActive ? classes.navitemactive : classes.navitem} to="/">Home</NavLink>
          </Nav.Link >
          <Nav.Link as="div">
            <NavLink className={(navData) => navData.isActive ? classes.navitemactive : classes.navitem} to="/About">About</NavLink>
          </Nav.Link>
          <Nav.Link as="div">
            <NavLink className={(navData) => navData.isActive ? classes.navitemactive : classes.navitem}  to="/OurWork">OurWork</NavLink>
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default MainNavigation;
