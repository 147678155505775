import { useState } from "react";
import TikTokVideo from "./TikTokVideo";
import classes from "./TikTokList.module.css";

const TikTokList = () => {
  const [tikToks] = useState<string[]>([
    "https://www.tiktok.com/@sherbzpowdrworx/video/7040502622972906798",
    "https://www.tiktok.com/@sherbzpowdrworx/video/7045467014051450158",
    "https://www.tiktok.com/@sherbzpowdrworx/video/7043411330862402863"
  ]);

  return (
    <ul className={classes.list}>
      {tikToks.map((tikTok) => (
        <TikTokVideo key={tikTok} tikTokUrl={tikTok} />
      ))}
    </ul>
  );
};
export default TikTokList;
