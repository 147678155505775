import ServicePicList from "../components/servicePics/ServicePicList";
import { useState } from "react";
import Service from "../interfaces/service";

const SandBlastingPage = () => {
  const [services] = useState<Service[]>([
    {
      Title: "Eldebrock Covers",
      Description:
        "Before and after of some Eldebrock covers we restored with the sandblasting booth.",
      url: "sandBlasting1.jpg",
    },
    {
      Title: "Speaker Covers",
      Description:
        "Before and after of some speaker covers we restored with the sandblasting booth.",
      url: "sandBlasting2.jpg",
    },
  ]);
  return <ServicePicList services={services} />;
};
export default SandBlastingPage;
