const Intro = () => {
  return (
    <p>
      SherbzPowdrWorx is locally owned shop in Calhoun county. We offer Powder
      Coating, Sandblasting and Chemical Stripping Services. There is no job too
      big or too small. Let us know how we can help you on your next project.
      <br />
      You can reach us at 256-454-4941 or Sherbzpowdrworx@gmail.com.
      <br />
      You can follow us on{" "}
      <a href="https://www.facebook.com/Sherbz-PowdrWorx-104658365393557">
        Facebook
      </a>{" "}
      or on <a href="https://www.tiktok.com/@sherbzpowdrworx">TikTok</a>
    </p>
  );
};
export default Intro;
