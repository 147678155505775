import Service from "../../interfaces/service";
import ServicePic from "./ServicesPic";
import { Col, Row } from "react-bootstrap";


const ServicePicList:React.FC<{services:Service[]}> = (props) => {
  // const [imgCards] = useState<Service[]>([
  //   {
  //     Title: "Eldebrock Covers",
  //     Description:
  //       "Before and after of some Eldebrock covers we restored with the sandblasting booth.",
  //     url: "sandBlasting1.jpg",
  //   },
  //   {
  //     Title: "Speaker Covers",
  //     Description:
  //       "Before and after of some speaker covers we restored with the sandblasting booth.",
  //     url: "sandBlasting2.jpg",
  //   },
  // ]);
  return (
    <div>
      <Row>
        {props.services.map((service) => (
          <Col>
            <ServicePic service={service} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default ServicePicList;
