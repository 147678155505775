import classes from "./Layout.module.css";
import MainNavigation from "./MainNavigation";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel,Container } from "react-bootstrap";
// import { useState } from "react";

const Layout: React.FC = (props) => {
  // const [images] = useState<string[]>([
  //   "sherbzLogo.jpg",
  //   "Shop.jpg",
  //   "BatmanParking.jpg",
  //   "Speakers.jpg",
  //   "NeonTumbler.jpg",
  //   "BlueTumbler.jpg",
  //   "Shop2.jpg",
  //   "OrangeAndBlueTumbler.jpg",
  // ]);
  return (
    <Container fluid>
      <MainNavigation />
      {/* <img className={classes.center} height="400"  src={process.env.PUBLIC_URL + "SPWMain2.jpg"} alt="" /> */}
      <Carousel fade>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "sherbzLogo.jpg"}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "Shop.jpg"}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "BatmanParking.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "Speakers.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "NeonTumbler.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "BlueTumbler.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "Shop2.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "OrangeAndBlueTumbler.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height="500"
            className="d-block w-100"
            src={process.env.PUBLIC_URL + "sandBlasting1.jpg"}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <main className={classes.main}>{props.children}</main>
    </Container>
  );
};
export default Layout;
