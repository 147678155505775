import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/HomePage";
import OurWorkPage from "./pages/OurWorkPage";
import AboutPage from "./pages/AboutPage";
import SandBlastingPage from "./pages/SandBlastingPage";
import PowderCoatingPage from "./pages/PowderCoatingPage";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/OurWork" element={<OurWorkPage />}></Route>
        <Route path="/About" element={<AboutPage />}></Route>
        <Route path="/SandBlasting" element={<SandBlastingPage />}></Route>
        <Route path="PowderCoating" element={<PowderCoatingPage />}></Route>
      </Routes>
    </Layout>
  );
}

export default App;
