import ServicePicList from "../components/servicePics/ServicePicList";
import { useState } from "react";
import Service from "../interfaces/service";

const PowderCoatingPage = () => {
  const [services] = useState<Service[]>([
    {
      Title: "Batman Parking",
      Description: "Batman Parking Sign PowderCoated Black",
      url: "BatmanParking.JPG",
    },
    {
      Title: "Blue RTC Tumbler",
      Description: "RTC Tumbler Powder Coated Blue",
      url: "BlueTumbler.JPG",
    },
  ]);

  return <ServicePicList services={services} />;
};

export default PowderCoatingPage;
